import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/util/seo"
import PostContent from "../components/content/postContent";
import "../assets/scss/layout/pages/credits.scss"

const Credits = ({ data, path }) => {
  const content = data.wpPage

  return (
    <Layout mt={true} path={path}>
      <SEO title={content.title} />
      <div className={'credits'}>
        <h1>{content.title}</h1>
        <div className={'grid-1 grid-lg-2'}>
          <div className={'content__fr d-lg-flex'}>
            <p className={'lang'}>FR</p>
            <PostContent className={'translated__content'} content={content.content} />
          </div>
          <div className={'content__en d-lg-flex'}>
            <p className={'lang'}>EN</p>
            <PostContent className={'translated__content'} content={content.ACF.content} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(slug: {eq: "credits"}) {
      ACF {
        content
      }
      content
      title
    }
  }
`

export default Credits
